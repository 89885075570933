<template>
  <div
    class="primary-menu"
    :class="primaryMenuClass"
  >
    <HeaderArrowMenu
      :items="primaryNavMenuItems"
      :theme="primaryMenuTheme"
      @click-menu-item="onClickMenuItem"
    />
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';

  import { isHomeLink, getRelativeLink, getFullUrl } from '~/utils';

  import { HOME_URL, WORDPRESS_API_URL } from '~/constants/config';
  import HeaderArrowMenu from '~/components/HeaderArrowMenu.vue';
  import type { TTheme } from '~/components/ArrowMenu/ArrowMenu';

  export default defineNuxtComponent({
    name: 'PrimaryMenu',

    components: {
      HeaderArrowMenu,
    },

    props: {
      location: {
        type: String,
        default: '',
      },
      type: {
        type: String as PropType<string>,
        default: '',
      },
      primaryNavMenuItems: {
        type: Array as PropType<MenuItem[]>,
        required: true,
      },
    },

    computed: {
      primaryMenuClass() {
        return this.location ? `primary-menu--${this.location}` : null;
      },
      primaryMenuTheme(): TTheme {
        return this.location === 'mobile' ? 'light' : 'red';
      },
      items(): Array<MenuItem> {
        return this.primaryNavMenuItems.map((item) => {
          const result = { ...item };

          result.isInternalUrl = isHomeLink(item.link);
          result.isActive = this.isActive(item.link);
          result.link = result.isInternalUrl ? getRelativeLink(item.link) : item.link;

          return result;
        });
      },
    },

    methods: {
      isActive(link: string): boolean {
        const linkWithoutHostName = this.getLinkWithHostName(link);

        // #1nuu6ab
        // адреса для рецептов имеют вид: /recipe/[slug]
        // адрес главной рецептов имеет вид: /recipes/
        // в случае если это рецепт - здесь надо возвращать true
        const recipeRegex = /^\/recipe[s]?\//g;
        // если текущий route.path - рецепт
        // присваем routePath значение '/recipes/'
        // чтобы совпадало со ссылкой в меню, пришедшей с сервера
        const routePath = recipeRegex.test(useRoute().path) ? '/recipes/' : useRoute().path;

        return routePath === linkWithoutHostName;
      },

      getLinkWithHostName(link: string): string {
        return link.replace(WORDPRESS_API_URL, '').replace(HOME_URL, '');
      },

      onClickMenuItem(item: MenuItem): void {
        this.$sendYandexMetrika({
          level1: 'Клик_Хедер',
          level4: getFullUrl(item.link),
          level5: item.title,
          level6: 'Первый уровень',
        });
      },
    },
  });
</script>

<style lang="scss">
  .primary-menu {
    position: relative;

    @include big-tablet {
      padding-left: 16px;
    }

    @include desktop {
      padding-left: 0;
    }

    &--desktop {
      display: none;
      overflow-x: hidden;
      height: 100%;
      width: 100%;

      @include big-tablet {
        display: flex;
      }
    }

    &--mobile {
      display: flex;
      align-items: stretch;
      min-height: 48px;
      background-color: #ffffff;
      border-bottom: 1px solid #e7e7e7;

      @include big-tablet {
        display: none;
      }
    }
  }
</style>
