<template>
  <div class="arrow-menu-wrapper">
    <ArrowMenu
      class="arrow-menu-test"
      :theme="theme"
      :items="items"
      v-bind="$attrs"
    />
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import ArrowMenu from '~/components/ArrowMenu.vue';
  import type { TTheme } from '~/components/ArrowMenu/ArrowMenu';

  export default defineNuxtComponent({
    name: 'HeaderArrowMenu',
    components: {
      ArrowMenu,
    },
    props: {
      items: {
        type: Array as PropType<MenuItem[]>,
        required: true,
      },
      theme: {
        type: String as PropType<TTheme>,
        default: 'light',
      },
    },
  });
</script>

<style lang="scss" scoped>
  .arrow-menu-wrapper {
    max-width: 1248px;
    width: 100%;
    padding: 0 8px;
  }
</style>
